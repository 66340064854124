import * as React from 'react';

import { Layout, SEO, Map, Contact, GalleryGrid } from '../components';

function GalleryPage() {
  return (
    <Layout>
      <SEO title="Gallery" />
      <GalleryGrid />
      <Contact />
      <Map />
    </Layout>
  );
}

export default GalleryPage;
